exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-achievements-tsx": () => import("./../../../src/pages/achievements.tsx" /* webpackChunkName: "component---src-pages-achievements-tsx" */),
  "component---src-pages-ccc-tsx": () => import("./../../../src/pages/ccc.tsx" /* webpackChunkName: "component---src-pages-ccc-tsx" */),
  "component---src-pages-class-progression-tsx": () => import("./../../../src/pages/class-progression.tsx" /* webpackChunkName: "component---src-pages-class-progression-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-hack-tsx": () => import("./../../../src/pages/hack.tsx" /* webpackChunkName: "component---src-pages-hack-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-kids-coding-projects-tsx": () => import("./../../../src/pages/kids-coding-projects.tsx" /* webpackChunkName: "component---src-pages-kids-coding-projects-tsx" */),
  "component---src-pages-nest-tsx": () => import("./../../../src/pages/nest.tsx" /* webpackChunkName: "component---src-pages-nest-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-self-guided-javablocks-tsx": () => import("./../../../src/pages/self-guided/javablocks.tsx" /* webpackChunkName: "component---src-pages-self-guided-javablocks-tsx" */),
  "component---src-pages-self-guided-processing-tsx": () => import("./../../../src/pages/self-guided/processing.tsx" /* webpackChunkName: "component---src-pages-self-guided-processing-tsx" */),
  "component---src-pages-staff-meetings-tsx": () => import("./../../../src/pages/staff-meetings.tsx" /* webpackChunkName: "component---src-pages-staff-meetings-tsx" */),
  "component---src-pages-videolectures-tsx": () => import("./../../../src/pages/videolectures.tsx" /* webpackChunkName: "component---src-pages-videolectures-tsx" */),
  "component---src-templates-1-on-1-tutoring-index-tsx": () => import("./../../../src/templates/1-on-1-tutoring/index.tsx" /* webpackChunkName: "component---src-templates-1-on-1-tutoring-index-tsx" */),
  "component---src-templates-class-details-tsx": () => import("./../../../src/templates/ClassDetails.tsx" /* webpackChunkName: "component---src-templates-class-details-tsx" */),
  "component---src-templates-classes-tsx": () => import("./../../../src/templates/classes.tsx" /* webpackChunkName: "component---src-templates-classes-tsx" */),
  "component---src-templates-electives-tsx": () => import("./../../../src/templates/electives.tsx" /* webpackChunkName: "component---src-templates-electives-tsx" */),
  "component---src-templates-game-based-classes-cancel-tsx": () => import("./../../../src/templates/game-based-classes/cancel.tsx" /* webpackChunkName: "component---src-templates-game-based-classes-cancel-tsx" */),
  "component---src-templates-game-based-classes-checkout-tsx": () => import("./../../../src/templates/game-based-classes/checkout.tsx" /* webpackChunkName: "component---src-templates-game-based-classes-checkout-tsx" */),
  "component---src-templates-game-based-classes-classes-tsx": () => import("./../../../src/templates/game-based-classes/classes.tsx" /* webpackChunkName: "component---src-templates-game-based-classes-classes-tsx" */),
  "component---src-templates-game-based-classes-success-tsx": () => import("./../../../src/templates/game-based-classes/success.tsx" /* webpackChunkName: "component---src-templates-game-based-classes-success-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-in-person-classes-tsx": () => import("./../../../src/templates/in-person-classes.tsx" /* webpackChunkName: "component---src-templates-in-person-classes-tsx" */),
  "component---src-templates-research-single-tsx": () => import("./../../../src/templates/research-single.tsx" /* webpackChunkName: "component---src-templates-research-single-tsx" */),
  "component---src-templates-research-tsx": () => import("./../../../src/templates/research.tsx" /* webpackChunkName: "component---src-templates-research-tsx" */),
  "component---src-templates-staff-tsx": () => import("./../../../src/templates/staff.tsx" /* webpackChunkName: "component---src-templates-staff-tsx" */)
}

